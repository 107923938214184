import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

export function toggleMenuOpen() {
  if (nav.classList.contains('nav--open')) {
    disableScrollLock();
    nav.classList.remove('nav--open');

    // if (window.pageYOffset > 50) {
    //     setTimeout(() => nav.classList.add('going-up'), 40);
    // }
  } else {
    enableScrollLock();
    nav.classList.add('nav--open');
  }
}

export function setupNav(selector = '.nav', sticky = true) {
  nav = document.body.querySelector(selector);

  if (nav) {
    const navBtn = nav.querySelector('.nav__btn');

    navBtn.addEventListener('click', toggleMenuOpen);

    if (sticky) {
      stickyNavOnScroll(nav, 'nav--sticky', 70, 'going-up');
    }
  }

  function disableDisableScrollLock(x) {
    const navSubs = document.querySelectorAll('.nav .nav__sub');
    const openButtons = document.querySelectorAll(
      '.nav button[data-action*="accordion"]'
    );

    if (x.matches) {
      // If media query matches
      disableScrollLock();
      nav.classList.remove('nav--open');

      navSubs.forEach((navsub) => (navsub.style.height = 'auto'));
    } else {
      navSubs.forEach((navsub) => {
        navsub.style.height = '0px';
      });

      openButtons.forEach((openButton) => {
        openButton.classList.remove('open');
        openButton.setAttribute('aria-expanded','false');
      });
    }
  }

  var x = window.matchMedia('(min-width: 1260px)');
  disableDisableScrollLock(x); // Call listener function at run time
  x.addListener(disableDisableScrollLock); // Attach listener function on state changes
}
