export const transitionEndEventName = detectStyleDeclatationName();
export const animationEndEventName = detectStyleDeclatationName("animation");

/**
 * Detects which prefixed eventName is in CSSStyleDeclaration
 *
 * @param {string} eventTypeName - choose 'transition' or 'animation'
 * @returns {string}
 */
export function detectStyleDeclatationName(eventTypeName = "transition") {
    const transitions = {
        transition: `${eventTypeName}end`,
        OTransition: `o${eventTypeName}end`,
        MozTransition: `${eventTypeName}end`,
        WebkitTransition: `webkit${eventTypeName}end`,
    };

    const el = document.createElement("div");

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return "";
}

/**
 *
 * @param {HTMLElement} container - container to animate height of
 * @param {HTMLElement} classTarget [default = container] - target element that gets class
 * @param {Boolean} classOnBoth [default = false] - set class on both container and classTarget
 */
export function cssAnimateNewHeight(
    container,
    classTarget = container,
    classOnBoth = false
) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove("open");
            classTarget.classList.remove("open");
        } else {
            classTarget.classList.remove("open");
        }

        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute("style");

    if (classTarget.classList.contains("open")) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove("open");
        }
    } else {
        classTarget.classList.add("open");

        if (classOnBoth) {
            container.classList.add("open");
        }

        newHeight = container.offsetHeight;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 15);
}

export function scrollAnimations() {
    //Scroll trigger for text-image
    const blockAnimates = document.querySelectorAll(".block-animate");
    for (let i = 0; i < blockAnimates.length; i++) {
        const blockAnimate = blockAnimates[i];
        const aLeft = blockAnimate.querySelectorAll(".block-animate--left");
        const aRight = blockAnimate.querySelectorAll(".block-animate--right");
        const img = blockAnimate.querySelector(img);

        if (aLeft || aRight) {
            const isReversed = blockAnimate.classList.contains(
                "text-image--reverse"
            );

            const timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: blockAnimate,
                    toggleActions: "play none none none",
                    start: "top bottom",
                    // markers: true,
                },
            });

            if (aLeft) {
                for (let i = 0; i < aLeft.length; i++) {
                    const aLeftElem = aLeft[i];

                    timeLine.from(aLeftElem, {
                        x: isReversed ? 200 : -200,
                        alpha: 0,
                        ease: "power3.out",
                        duration: 0.7,
                    });
                }
            }
            if (aRight) {
                for (let i = 0; i < aRight.length; i++) {
                    const aRightElem = aLeft[i];

                    timeLine.from(aRight,{
                            x: isReversed ? -200 : 200,
                            alpha: 0,
                            ease: "power3.out",
                            duration: 0.7,
                        },
                        "-=.7"
                    );
                }
            }
            if (img) {
                img.addEventListener("lazyloaded", () => {
                    ScrollTrigger.refresh();
                });
            }
        }
    }
}
