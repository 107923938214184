import { scrollTo } from '../utils/scrollTo';

/**
 * This function strips relative site path from hashlink 
 * 
 * @param {string} hashLink 
 * 
 * @returns {HTMLElement}
 */
function returnHashElement(hashLink) {
    if (hashLink.indexOf(window.location.pathname) !== -1) {
        return document.getElementById(hashLink.replace(window.location.pathname, '').replace('#', ''));
    } else {
        return document.getElementById(hashLink.replace('#', ''));
    }
}

/**
 * Scrolls the viewport to an hash-id
 * if found in querystring
 */
export function scrollToUrlHash() {
    if (window.location.hash) {
        const element = document.getElementById(window.location.hash.replace('#', ''));

        if (element) {
            scrollTo(element);
        }
    }
}

/**
 * Activate Anchor Link Scroll with this function
 * Will only run if given selector elements are found in DOM
 *  
 * @param {string} triggerSelector 
 */

export function setupAnchorLinkScroll(triggerSelector = '[data-action*="anchor"], a[href^="#"]:not([href="#"]):not([role="tab"]):not([data-action*="accordion"])') {

    const triggers = document.querySelectorAll(triggerSelector);

    for (let i = 0; i < triggers.length; i++) {
        const trigger = triggers[i];
        const hashLink = trigger.getAttribute('href') || trigger.getAttribute('data-target');

        if (hashLink) {
            const element = returnHashElement(hashLink);

            trigger.addEventListener('click', e => {
                e.preventDefault();
                scrollTo(element);
            });
        }
    }
}
