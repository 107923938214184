const logWarning = (toggleSelector, type) => {
    if (toggleSelector == null || toggleSelector == undefined) {
        console.warn("'Toggle' can't find element of type: " + type);
        return true;
    }
    return false;
};

const toggleStuff = (self, toggleSelector, toggleClass) => {
    let type;

    if (toggleSelector === "sibling") {
        toggleSelector = self.nextElementSibling;
        type = "Sibling";
    } else if (toggleSelector === "body") {
        toggleSelector = document.body;
        type = "Body";
    } else if (toggleSelector != null && toggleSelector.length > 0) {
        switch (toggleSelector.charAt(0)) {
            case "#":
                toggleSelector.substring(1);
                toggleSelector = document.getElementById();
                type = "Id";
                break;

            case ".":
                toggleSelector = document.querySelector(toggleSelector);
                type = "Class";
                break;
        }
    } else {
        toggleSelector = self;
    }
    if (!logWarning(toggleSelector, type)) {
        toggleSelector.classList.toggle(toggleClass);
    } else {
        return;
    }
};

/**
 * Toggle have the following attributes
 * data-action="toggle"
 * data-toggle-class="active"
 * data-toggle-selector="sibling" This can be '' for self, 'sibling* for next sibling
 * '#something' for ID or '.something' for a class
 *
 * <div class="site-header__search__toggle" data-action="toggle" data-toggle-selector="sibling" data-toggle-class="active">
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class Toggle {
    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} trigger
     */
    constructor(trigger) {
        this.dom = {
            trigger,
            container: trigger.dataset.toggleSelector || null,
        };

        // trigger.nextElementSibling
        this.settings = {
            class: trigger.dataset.toggleClass || "open",
        };

        this.dom.trigger.addEventListener("click", (e) => {
            toggleStuff(trigger, this.dom.container, this.settings.class);
        });
    }
}

export function setupToggles(selector = '[data-action*="toggle"]') {
    const toggles = document.querySelectorAll(selector);

    if (toggles) {
        for (let i = 0; i < toggles.length; i++) {
            void new Toggle(toggles[i]);
        }
    }
}
