import { onWindowResize, currentBreakpoint, currentBreakpointIndex } from './utils/windowResize';
// import { onScroll, scrollTop } from './utils/scroll';
import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
// import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupToggles } from './components/toggle';
// import { setupVideos } from './components/video';
// import { initVhUnitOverwrite, debounce } from './utils/helpers';
// import { version } from '../../package.json';
import { setupAnchorLinkScroll } from './components/anchors';
// import { setupFilters } from './components/filter';
// import { setupOverlay } from './components/overlay';
// import { setupTabs } from './components/tabs';
// import { setupYTVideos } from './components/video-yt';
// import { setupSliders } from './components/slider';
// import { watchFormFields } from './components/form';
import { scrollAnimations } from './utils/animation';
import lazySizes from 'lazysizes';

// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';
lazySizes.cfg.loadMode = 1;
lazySizes.cfg.preloadAfterLoad = false;
lazySizes.autoSizer.checkElems();

function init() {
    document.body.classList.remove('standby');
    setTimeout(() => {
        document.body.classList.remove('standby-animation');
    }, 500);

 
    setupAnchorLinkScroll(); 

    setupNav('.nav');
    scrollAnimations();

    onReady(() => { 
        // initVhUnitOverwrite();

        // setupLazyLoading();

        setupAccordions();

        setupToggles();

        // setupFilters();

        // setupOverlay(); 

        // setupVideos();

        // setupYTVideos();

        // setupTabs();

        // setupSliders();

        // watchFormFields();
    });

    // Simulated CookieBot function-call via GTM
    //window.showCookieBanner();

    // onWindowResize event demo
    // I recommend you remove this before going live ;)
    // onWindowResize(() => {
    //     window.console.log('Resize Event (throuttled)', {currentBreakpoint, currentBreakpointIndex});
    // });

    // onScroll event demo
    // Remove when you get tired of the console spam
    // onScroll(() => {
    //     window.console.log('Scroll Event (RAF)', scrollTop);
    // });


    window.addEventListener('load', () => {
        // Polyfill for using svg spritesheet in oldIE
        svg4everybody();
    });
}

init();
