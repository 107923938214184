import {
    getElementScroll
} from "./elementProperties";
import {
    scrollLocked
} from "./scrollLock";
import {
    onScroll
} from "./scroll";

/**
 *
 * @param {HTMLElement} element - element to add sticky class to
 * @param {string} className - sticky class name to add on scroll
 * @param {number} scrollInPixels - number of pixels before activating scroll
 * @param {string} goingUpClass - class added when scrolling up
 */

export function stickyNavOnScroll(element, className = "nav--sticky", scrollInPixels = 52, goingUpClass = "nav--going-up") {
    var doc = document.documentElement;
    var w = window;

    /*
    define four variables: curScroll, prevScroll, curDirection, prevDirection
    */

    var curScroll;
    var prevScroll = w.scrollY || doc.scrollTop;
    var curDirection = 0;
    var prevDirection = 0;

    /*
    how it works:
    -------------
    create a scroll event listener
    create function to check scroll position on each scroll event,
    compare curScroll and prevScroll values to find the scroll direction
    scroll up - 1, scroll down - 2, initial - 0
    then set the direction value to curDirection
    compare curDirection and prevDirection
    if it is different, call a function to show or hide the header
    example:
    step 1: user scrolls down: curDirection 2, prevDirection 0 > hide header
    step 2: user scrolls down again: curDirection 2, prevDirection 2 > already hidden, do nothing
    step 3: user scrolls up: curDirection 1, prevDirection 2 > show header
    */

    var header = element;
    var toggled;
    var threshold = 150;

    // console.log(header);

    var checkScroll = function() {
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            // scrolled down
            curDirection = 2;
        } else {
            //scrolled up
            curDirection = 1;
        }

        if (curDirection !== prevDirection) {
            toggled = toggleHeader();
        }

        prevScroll = curScroll;
        if (toggled) {
            prevDirection = curDirection;
        }


        if (curScroll > threshold) {
            header.classList.add(className);
        } else if (curScroll < threshold) {
            header.classList.remove(className);
        }

    };

    var toggleHeader = function() {
        toggled = true;

        if (curDirection === 2 && curScroll > threshold) {
            header.classList.remove(goingUpClass);
        } else if (curDirection === 1) {
            header.classList.add(goingUpClass);
        } else {
            toggled = false;
        }
        return toggled;
    };

    window.addEventListener("scroll", checkScroll);
}